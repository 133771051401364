
import { defineComponent } from 'vue';

//import Swal from "sweetalert2/dist/sweetalert2.min.js";
import Datatable from '@/components/kt-datatable/kTDatatableLeft.vue';
import * as Yup from 'yup';
import ApiService from '@/core/services/ApiService';
import Swal from 'sweetalert2/dist/sweetalert2.min.js';
import { VueCookieNext } from 'vue-cookie-next';
import { Form, Field, ErrorMessage } from 'vee-validate';
import moment from 'moment';
import axios from 'axios';
import { ElNotification } from 'element-plus';
import { apiEndpoint } from '@/mixin/apiEndpoint.js';
export default defineComponent({
  mixins: [apiEndpoint],
  name: 'Advance Search Certified Trainee',
  components: {
    Datatable,
    Field,
  },
  data() {
    return {
      pdfHeader: [] as any,
      moment: '' as any,
      tranches: [] as any,
      TranceData: [] as any,
      start_date: '',
      end_date: '',
      TranceLabel: [] as any,
      TpartnerLabel: [] as any,
      TinstituteLabel: [] as any,
      TCourseTypeLabel: [] as any,
      TCourseListLabel: [] as any,
      TCourseLabel: [] as any,
      TbatchesLabel: [] as any,
      selectedDistricts: [] as any,
      LicenseData: [],
      selectedTrance: [],

      licenseProvided: [
        {
          id: 1,
          name: 'Yes',
        },
        {
          id: 2,
          name: 'No',
        },
      ],
      updatedArray: [] as any,
      TpartnerData: [] as any,
      CourseTypeData: [] as any,
      CourseListData: [] as any,
      TinstituteData: [] as any,
      TbatchData: [] as any,
      genderData: [] as any,
      dropout: '' as any,
      absent: '' as any,
      searchType: '' as any,
      isDropOut: '' as any,
      isAbsent: '' as any,
      tableData: [] as any,
      api_url: '',

      certifiedHeader: [
        {
          name: 'Photo',
          key: 'photo',
        },
        {
          name: 'Entity',
          key: 'association',
          sortable: true,
          selected: true,
        },
        {
          name: 'Training Institute',
          key: 't_institute',
          sortable: true,
          selected: true,
          width: '120px',
        },
        {
          name: 'Course',
          key: 'course',
          sortable: true,
          selected: true,
          width: '150px',
        },
        {
          name: 'Tranche',
          key: 'tranche',
          sortable: true,
          selected: true,
        },
        {
          name: 'Batch',
          key: 'batch',
          sortable: true,
          selected: true,
        },
        {
          name: 'Registration Number',
          key: 'reg_num',
          sortable: true,
          selected: true,
        },
        {
          name: 'Reference Number',
          key: 'ref_num',
          sortable: true,
          selected: true,
        },
        {
          name: 'Trainee Name',
          key: 'trainee_name',
          sortable: true,
          selected: true,
          width: '120px',
        },
        {
          name: 'Gender',
          key: 'gender',
          sortable: true,
          selected: true,
        },

        {
          name: 'NID',
          key: 'nid',
          sortable: true,
          selected: true,
        },
        {
          name: 'BCN',
          key: 'bcn',
          sortable: true,
          selected: true,
        },
        {
          name: 'Date of Birth',
          key: 'dob',
          sortable: true,
          width: '120px',
        },

        // ok
        {
          name: 'Present Address',
          key: 'present_add',
          sortable: true,

          width: '150px',
        },
        {
          name: 'Present Post Code',
          key: 'pre_postcode',
          sortable: true,
        },
        {
          name: 'Present District',
          key: 'present_district',
          sortable: true,
          width: '150px',
        },
        {
          name: 'Present Upazila',
          key: 'pre_upazila',
          sortable: true,
          width: '150px',
        },
        {
          name: 'Permanent Address',
          key: 'permanent_add',
          sortable: true,
          width: '150px',
        },
        {
          name: 'Permanent Post Code',
          key: 'per_postcode',
          sortable: true,
        },
        {
          name: 'Permanent District',
          key: 'permanent_district',
          sortable: true,
          width: '150px',
        },
        {
          name: 'Permanent Upazila',
          key: 'per_upazila',
          sortable: true,
          width: '150px',
        },
        {
          name: 'Home District',
          key: 'home_dist',
          sortable: true,
          width: '150px',
        },
        {
          name: 'Home Upazila',
          key: 'home_upazila',
          sortable: true,
          width: '150px',
        },
        {
          name: 'Mobile',
          key: 'mobile',
          sortable: true,
        },
        {
          name: 'Alternative Mobile',
          key: 'alt_mobile',
          sortable: true,
        },
        {
          name: 'Email',
          key: 'email',
          sortable: true,
        },
        {
          name: 'Religion',
          key: 'religion',
          sortable: true,
        },
        {
          name: 'Ethnic Group',
          key: 'ethnic',
          sortable: true,
        },
        {
          name: 'Highest Class Completed',
          key: 'hcc',
          sortable: true,
        },
        {
          name: 'Highest Class Completed Year',
          key: 'hccy',
          sortable: true,
        },
        {
          name: 'Is Employed',
          key: 'is_employed',
          sortable: true,
        },
        {
          name: 'Year Of Experience',
          key: 'year_of_exp',
          sortable: true,
        },
        {
          name: 'Family Monthly Income',
          key: 'fmi',
          sortable: true,
        },
        {
          name: 'Is Physically Challenged',
          key: 'ipc',
          sortable: true,
        },
        {
          name: 'Challenge Remarks',
          key: 'cr',
          sortable: true,
        },
        {
          name: 'Mother',
          key: 'mother',
          sortable: true,
        },
        {
          name: 'Mother Education Level',
          key: 'mel',
          sortable: true,
        },
        {
          name: 'Mother Occupation',
          key: 'mother_ocu',
          sortable: true,
        },
        {
          name: 'Father',
          key: 'father',
          sortable: true,
        },
        {
          name: 'Father Education Level',
          key: 'fel',
          sortable: true,
        },
        {
          name: 'Father Occupation',
          key: 'f_occupation',
          sortable: true,
        },
        {
          name: 'Father Annual Income',
          key: 'fai',
          sortable: true,
        },
        {
          name: 'Have Family Owned Home',
          key: 'hfoh',
          sortable: true,
        },
        {
          name: 'Have Family Owned Land',
          key: 'hfol',
          sortable: true,
        },
        {
          name: 'Number Of Siblings',
          key: 'nos',
          sortable: true,
        },
        {
          name: 'Bank Name',
          key: 'bank_name',
          sortable: true,
        },
        {
          name: 'Bank Branche Name',
          key: 'bbn',
          sortable: true,
        },
        {
          name: 'Bank Routing No',
          key: 'brn',
          sortable: true,
        },
        {
          name: 'Bank Account Number',
          key: 'ban',
          sortable: true,
        },
        {
          name: 'Account Type',
          key: 'account_type',
          sortable: true,
        },
        {
          name: 'X Bank',
          key: 'x_bank',
          sortable: true,
        },
        {
          name: 'X Mobile banking',
          key: 'x_mobile_banking',
          sortable: true,
        },
        {
          name: 'Mobile Banking Name',
          key: 'mbn',
          sortable: true,
        },
        {
          name: 'Mobile Banking Prefix',
          key: 'mbp',
          sortable: true,
        },
        {
          name: 'Agent Banking Name',
          key: 'abn',
          sortable: true,
        },

        {
          name: 'Transaction Type',
          key: 'transaction_type',
          sortable: true,
        },
        {
          name: ' Batch Start Date',
          key: 'batch_start_date',
          sortable: true,
          width: '120px',
        },
        {
          name: ' Batch End Date',
          key: 'batch_end_date',
          sortable: true,
          width: '120px',
        },
        {
          name: 'Attendance Percentage',
          key: 'att_perc',
          sortable: true,
        },
        {
          name: 'Selected For Stipend',
          key: 'sfs',
          sortable: true,
        },
        {
          name: 'Selection Date',
          key: 'sel-date',
          sortable: true,
        },
        {
          name: 'Bank Verified',
          key: 'bank_ver',
          sortable: true,
        },
        {
          name: 'Verification Date',
          key: 'ver-date',
          sortable: true,
        },
        {
          name: 'Stipend Disbursed',
          key: 'stip-disburse',
          sortable: true,
        },
        {
          name: 'Disbursement Date',
          key: 'disb-date',
          sortable: true,
        },
        {
          name: 'Disbursed Amount',
          key: 'disb_amount',
          sortable: true,
        },
        {
          name: 'Disbursement Remarks',
          key: 'dib_remarks',
          sortable: true,
        },
        {
          name: 'Is Poor',
          key: 'is_poor',
          sortable: true,
        },

        {
          name: 'Stipend Year',
          key: 'sti-year',
          sortable: true,
        },
        {
          name: 'Stipend Quarter',
          key: 'sti-quarter',
          sortable: true,
        },
        {
          name: 'Stipend Start Date',
          key: 'sti-start-date',
          sortable: true,
        },
        {
          name: 'Stipend End Date',
          key: 'sti-end-date',
          sortable: true,
        },
        {
          name: 'Income Certificate',
          key: 'income_cert',
          sortable: true,
        },
        {
          name: 'Trainee Remarks',
          key: 'trainee_remarks',
          sortable: true,
        },
        {
          name: 'Bank Verification Remarks',
          key: 'bank_v_r',
          sortable: true,
        },
        {
          name: 'Dup Enrollment',
          key: 'dup-enroll',
          sortable: true,
        },
        {
          name: 'Allow Dup Enrollment',
          key: 'allow_dup_enroll',
          sortable: true,
        },
        {
          name: 'Eligible Duplicate Courses',
          key: 'eligible_dup_course',
          sortable: true,
        },
        {
          name: 'Stipend Count',
          key: 'sti_count',
          sortable: true,
        },

        {
          name: 'Certification Date',
          key: 'cer_date',
          sortable: true,
        },
        {
          name: 'Recertification Date',
          key: 'recert-date',
          sortable: true,
        },
        {
          name: 'Final Grade',
          key: 'final-grade',
          sortable: true,
        },
        {
          name: 'Pass Percentage',
          key: 'pass-percentage',
          sortable: true,
        },
      ] as any,
      st: [] as any,
      instituteList: [] as any,
      courseType: [] as any,
      courseList: [] as any,
      batchList: [] as any,
      loading: false,
      courseInfoData: false,
      showCourseNotice: false,
      showtrainingProviderNotice: false,
      showTableData: false,
      showtable: true,
      showall: false,
      discardChangeData: false,
      declinechange: false,
      componentKey: 0,
      componentTableKey: 0,
      formattedHeader: [] as any,
      entityInfos: [] as any,
      formattedBody: [] as any,
      headerSelect: false,
      load: true,
      tableLoad: false,
    };
  },
  async created() {
    this.moment = moment;
    await this.getTranche();
    await this.associationList();
    await this.getCourseTypeList();
    this.api_url = this.VUE_APP_API_URL;
  },
  methods: {
    async exportTrainee() {
      ElNotification({
        dangerouslyUseHTMLString: true,
        message:
          '<i class="fa fa-download" aria-hidden="true"></i> <b>Downloading...</b>',
        duration: 0,
      });
      let formData = new FormData();
      this.pdfHeader = [] as any;
      for (let i = 0; i < this.st.length; i++) {
        this.pdfHeader.push({
          header: this.st[i]?.name,
          key: this.st[i]?.key,
        });
      }
      formData.set('certifiedHeader', JSON.stringify(this.pdfHeader));
      formData.set('entity', this.TpartnerData);
      formData.set('tranche', this.TranceData);
      formData.set('course', this.CourseListData);
      formData.set('institute', this.TinstituteData);
      formData.set('batch', this.TbatchData);
      formData.set('gender', this.genderData);

      axios
        .post(
          `${this.VUE_APP_API_URL}/api/report/certification_excel`,
          formData,
          {
            responseType: 'blob',
          }
        )
        .then((response) => {
          const url = URL.createObjectURL(
            new Blob([response.data], {
              type: 'application/vnd.ms-excel',
            })
          );
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', 'certification.xlsx');
          document.body.appendChild(link);
          link.click();
        })
        .catch((response) => {
          console.log(response);
        });
    },
    async printPdf() {
      ElNotification({
        dangerouslyUseHTMLString: true,
        message:
          '<i class="fa fa-download" aria-hidden="true"></i> <b>Downloading...</b>',
      });
      let formData = new FormData();
      this.pdfHeader = [] as any;
      for (let i = 0; i < this.st.length; i++) {
        this.pdfHeader.push({
          header: this.st[i]?.name,
          key: this.st[i]?.key,
        });
      }
      formData.set('certifiedHeader', JSON.stringify(this.pdfHeader));
      formData.set('entity', this.TpartnerData);
      formData.set('tranche', this.TranceData);
      formData.set('course', this.CourseListData);
      formData.set('institute', this.TinstituteData);
      formData.set('batch', this.TbatchData);
      formData.set('gender', this.genderData);

      let data = `${this.VUE_APP_API_URL}/api/report/certification_print`;
      axios.post(data, formData).then((response) => {
        //window.open(data, '_blank');
        document.write(response.data);
        window.print();
        location.reload();
        //open the new window and write your HTML to it
      });
    },
    async downloadPdf() {
      ElNotification({
        dangerouslyUseHTMLString: true,
        message:
          '<i class="fa fa-download" aria-hidden="true"></i> <b>Downloading...</b>',
        duration: 0,
      });
      let formData = new FormData();
      this.pdfHeader = [] as any;
      for (let i = 0; i < this.st.length; i++) {
        this.pdfHeader.push({
          header: this.st[i]?.name,
          key: this.st[i]?.key,
        });
      }

      formData.set('certifiedHeader', JSON.stringify(this.pdfHeader));
      formData.set('entity', this.TpartnerData);
      formData.set('tranche', this.TranceData);
      formData.set('course', this.CourseListData);
      formData.set('institute', this.TinstituteData);
      formData.set('batch', this.TbatchData);
      formData.set('gender', this.genderData);

      // console.log(JSON.stringify(this.st));

      await ApiService.post('report/certification_pdf', formData)
        .then((response) => {
          Swal.fire({
            title: 'Download Successfull!',
            icon: 'success',
            confirmButtonColor: '#3085d6',
            confirmButtonText: 'View/Download PDF',
          }).then((result) => {
            if (result.isConfirmed) {
              // redirect to new tab

              let path = `${this.VUE_APP_API_URL}/${response.data}`;

              window.open(path, '_blank');
            }
          });
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    trancheLabelChange(data) {
      // this.getCourseListData();
      this.TranceLabel = [];
      console.log(data);
      for (let i = 0; i < data.length; i++) {
        for (let j = 0; j < this.tranches.length; j++) {
          if (data[i] == this.tranches[j].id) {
            this.TranceLabel.push(this.tranches[j].label);
          }
        }
      }
    },
    trainingPartnerLabelChange(data) {
      // this.getCourseListData();
      this.getInstitute();
      this.TpartnerLabel = [];
      console.log(data);
      for (let i = 0; i < data.length; i++) {
        for (let j = 0; j < this.entityInfos.length; j++) {
          if (data[i] == this.entityInfos[j].id) {
            this.TpartnerLabel.push(this.entityInfos[j].entity_short_name);
          }
        }
      }
    },
    trainingInstituteLabelChange(data) {
      this.getCourseListData();

      this.TinstituteLabel = [];
      //console.log(data);
      for (let i = 0; i < data.length; i++) {
        for (let j = 0; j < this.instituteList.length; j++) {
          if (data[i] == this.instituteList[j].id) {
            this.TinstituteLabel.push(this.instituteList[j].short_name);
          }
        }
      }
    },
    async getCourseTypeList() {
      await ApiService.get('configurations/course_type/list')
        .then((response) => {
          this.courseType = response.data.data;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    async getCourseListData() {
      // this.load = true;
      await ApiService.get(
        'course/filterCourse?entity=' +
          this.TpartnerData +
          '&tranche=' +
          this.TranceData +
          '&courseType=' +
          this.CourseTypeData
      )
        .then((response) => {
          this.courseList = response.data.data;
          // this.load = false;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    courseTypeLabelChange(data) {
      this.getCourseListData();
      this.TCourseTypeLabel = [];
      //console.log(data);
      for (let i = 0; i < data.length; i++) {
        for (let j = 0; j < this.courseType.length; j++) {
          if (data[i] == this.courseType[j].id) {
            this.TCourseTypeLabel.push(this.courseType[j].type_name);
          }
        }
      }
    },
    courseListLabelChange(data) {
      this.getBatches();
      this.TCourseListLabel = [];
      //console.log(data);
      for (let i = 0; i < data.length; i++) {
        for (let j = 0; j < this.courseList.length; j++) {
          if (data[i] == this.courseList[j].id) {
            this.TCourseListLabel.push(this.courseList[j].course_name);
          }
        }
      }
    },

    tbatchLabelChange(data) {
      this.TbatchesLabel = [];
      //console.log(data);
      for (let i = 0; i < data.length; i++) {
        for (let j = 0; j < this.batchList.length; j++) {
          if (data[i] == this.batchList[j].id) {
            this.TbatchesLabel.push(this.batchList[j].batch_sequence_number);
          }
        }
      }
    },
    resetData() {
      this.tableData = [] as any;
      this.st = [] as any;
      (this.TranceLabel = [] as any),
        (this.TpartnerLabel = [] as any),
        (this.TinstituteLabel = [] as any),
        (this.TCourseTypeLabel = [] as any),
        (this.TCourseListLabel = [] as any),
        (this.TCourseLabel = [] as any),
        (this.TbatchesLabel = [] as any),
        (this.TpartnerData = [] as any),
        (this.CourseTypeData = [] as any),
        (this.CourseListData = [] as any),
        (this.TinstituteData = [] as any),
        (this.TbatchData = [] as any),
        (this.genderData = [] as any),
        (this.TranceData = [] as any),
        (this.dropout = '' as any),
        (this.absent = '' as any),
        (this.isDropOut = '' as any),
        (this.isAbsent = '' as any),
        (this.searchType = '' as any),
        (this.showall = false);
      this.showTableData = false;
      this.componentTableKey += 1;
    },
    async generatetraineeAdvanceReport() {

      if (this.TranceData != '') 
      {
       this.tableLoad = true;
      await ApiService.get(
        'certificate/advance_search?entity=' +
          this.TpartnerData +
          '&tranche=' +
          this.TranceData +
          '&course=' +
          this.CourseListData +
          '&institute=' +
          this.TinstituteData +
          '&batch=' +
          this.TbatchData +
          '&gender=' +
          this.genderData
      )
        .then((response) => {
          this.headerSelect = true;
          this.selectedTableHeader();
          this.tableData = response.data.data;
          console.log(this.tableData);
          this.componentTableKey += 1;
          this.showTableData = true;
          this.tableLoad = false;
        })
        .then((response) => {
          console.log(response);
        });
      }
      else {
         Swal.fire({
          title: 'Warning!',
          text: 'Please Select Tranche!',
          icon: 'warning',
          buttonsStyling: false,
          confirmButtonText: 'Ok',
          customClass: {
            confirmButton: 'btn btn-success',
          },
        });
        this.tableLoad = false;
      } 
    },
    async getTranche() {
      this.load = true;
      await ApiService.get('configurations/tranche/list')
        .then((response) => {
          this.tranches = response.data.data;
          this.load = false;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    async associationList() {
      this.load = true;
      let entity_id = '';
      if (VueCookieNext.getCookie('_seip_entity_type') != 1004) {
        entity_id = VueCookieNext.getCookie('_entity_id');
      }
      // console.log(entity_id);
      await ApiService.get('entity/list?entity_id=' + entity_id)
        .then((response) => {
          this.entityInfos = response.data.data;
          this.load = false;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    async getInstitute() {
      // this.load = true;
      let institute_id = '';
      if (VueCookieNext.getCookie('_seip_entity_type') == 1011) {
        institute_id = VueCookieNext.getCookie('_institute_info_id');
      }
      await ApiService.get(
        'institute/list?entity_id=' +
          this.TpartnerData +
          '&institute_info_id=' +
          institute_id
      )
        .then((response) => {
          console.log(response);
          this.instituteList = response.data.data;
          // this.load = false;
        })
        .catch((response) => {
          console.log(response);
        });
    },
    async getBatches() {
      // this.load = true;
      await ApiService.get(
        'batch/multiple-batch-filter?tranche=' +
          this.TranceData +
          '&entity=' +
          this.TpartnerData +
          '&institute=' +
          this.TinstituteData +
          '&course=' +
          this.CourseListData
      )
        .then((response) => {
          console.log(response);
          this.batchList = response.data.data;
          // this.load = false;
        })
        .catch((response) => {
          console.log(response);
        });
    },

    async getData() {
      if (this.TpartnerData.length || this.TranceData.length) {
        await ApiService.get(
          'report/motor-driving-batch?tranche=' +
            this.TranceData +
            '&entity=' +
            this.TpartnerData +
            '&institute=' +
            this.TinstituteData +
            '&batch=' +
            this.TbatchData +
            '&start_date=' +
            this.start_date +
            '&end_date=' +
            this.end_date
        )
          .then((response) => {
            this.headerSelect = true;
            this.selectedTableHeader();
            this.tableData = response.data.data;
            this.componentTableKey += 1;
          })
          .then((response) => {
            console.log(response);
          });
      } else {
        Swal.fire({
          title: 'Error Found',
          text: 'Tranche or Training Partner Required',
          icon: 'error',
          buttonsStyling: false,
          confirmButtonText: 'Close',
          customClass: {
            confirmButton: 'btn btn-danger',
          },
        });
      }
    },

    selectedTableHeader() {
      this.st = Array();

      for (let i = 0; i < this.certifiedHeader.length; i++) {
        if (this.certifiedHeader[i].selected) {
          this.st.push(JSON.parse(JSON.stringify(this.certifiedHeader[i])));
          // console.log(this.st);
        }
      }
      this.showTableData = true;
      this.showall = true;
    },
  },

  // beforeMount() {
  //   this.selectedTableHeader();
  // },
  computed: {},
  setup() {},
});
